@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

$autos: #547BA8;
$imoveis: #0099DA;
$empregos: #628C28;
$oportunidades: #5FBEBD;

* {
  --bs-breadcrumb-divider: ">";
  font-family: Lato;
}

.contact-input {
  height: 38px;
  font-size: 1rem !important;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.content {
  margin-bottom: 70px;
}

.App {
  padding-top: 44px;
  .banner {
    background-size: cover;
    height: 349px;
    color: white;
    text-align: center;
    padding-top: 100px;
    position: relative;
    margin-bottom: 120px;
  }
  &.autos {
    .banner {
      background-image: url("images/bg-auto.jpg");
    }
    .destaques {
      .box-detail {
        .btn-mais {
          background: $autos;
          &:hover {
            background: $autos;
          }
        }
      }
    }
    .menu-search {
      .filter {
        .filter-group {
          display: flex;
          flex-direction: column;
          .filterItem {
            order: 1;
          }
          .form-group {
            &:nth-child(1) {
              order: 2;
            }
            &:nth-child(2) {
              order: 3;
            }
            &:nth-child(3) {
              order: 4;
            }
            &:nth-child(4) {
              order: 5;
            }
            &:nth-child(5) {
              order: 6;
            }
            &:nth-child(6) {
              order: 7;
            }
            &:nth-child(7) {
              order: 8;
            }
            &:nth-child(8) {
              order: 9;
            }
            &:nth-child(9) {
              order: 10;
            }
            &:nth-child(10) {
              order: 9;
            }
          }
        }
      }
    }
    .contentList {
      .box-shadow {
        small, i, .result-actions, .price {
          color: $autos;
        }
      }

      .box-auto.box-shadow .btn-mais {
        background-color: $autos;
      }
    }
  }
  &.carreiras {
    .destaques {
      .box-detail {
        .btn-mais {
          background: $empregos;
          &:hover {
            background: $empregos;
          }
        }
      }
    }
    .contentList {
      .box-shadow {
        small, i, .result-actions, .price {
          color: $empregos;
        }
      }

      .box-shadow .btn-mais {
        background-color: $empregos;
      }
    }
  }
  &.imoveis {
    .destaques {
      .box-detail {
        .btn-mais {
          background: $imoveis;
          &:hover {
            background: $imoveis;
          }
        }
      }
    }
    .menu-search {
      .filter {
        .filter-group {
          display: flex;
          flex-direction: column;
          .filterItem {
            order: 1;
          }
          .form-group {
            &:nth-child(1) {
              order: 2;
            }
            &:nth-child(2) {
              order: 3;
            }
            &:nth-child(3) {
              order: 4;
            }
            &:nth-child(4) {
              order: 5;
            }
            &:nth-child(5) {
              order: 6;
            }
            &:nth-child(6) {
              order: 7;
            }
            &:nth-child(7) {
              order: 9;
            }
            &:nth-child(8) {
              order: 8;
            }
          }
        }
      }
    }
    .contentList {
      .box-shadow {
        small, i, .result-actions, .price {
          color: $imoveis;
        }
      }

      .box-shadow .btn-mais {
        background-color: $imoveis;
      }
    }
  }
  &.oportunidades {
    .destaques {
      .box-detail {
        .btn-mais {
          background: $oportunidades;
          &:hover {
            background: $oportunidades;
          }
        }
      }
    }
    .contentList {
      .box-shadow {
        small, i, .result-actions, .price {
          color: $oportunidades;
        }
      }

      .box-shadow .btn-mais {
        background-color: $oportunidades;
      }
    }
  }
  &.view {
    &.autos {
      aside {
        .form {
          background: $autos;
        }
        .price {
          h3 {
            color: $autos;
          }
          button {
            background: $autos;
          }
        }
      }
      .container-info {
        h5 {
          color: $autos;
        }
      }
      .anuncios-similares {
        h5 {
          color: $autos;
          &:after {
            background: $autos;
          }
        }
        .anuncios {
          .anuncio {
            .info {
              small {
                color: $autos;
              }
              .btn {
                background: $autos;
              }
            }
          }
        }
      }
    }
    &.carreiras-e-empregos {
      aside {
        .form {
          background: $empregos;
        }
        .price {
          h3 {
            color: $empregos;
          }
          button {
            background: $empregos;
          }
        }
      }
      .container-info {
        h5 {
          color: $empregos;
        }
      }
      .anuncios-similares {
        h5 {
          color: $empregos;
          &:after {
            background: $empregos;
          }
        }
        .anuncios {
          .anuncio {
            .info {
              small {
                color: $empregos;
              }
              .btn {
                background: $empregos;
              }
            }
          }
        }
      }
    }
    &.imoveis {
      aside {
        .form {
          background: $imoveis;
        }
        .price {
          h3 {
            color: $imoveis;
          }
          button {
            background: $imoveis;
          }
        }
      }
      .container-info {
        h5 {
          color: $imoveis;
        }
        .atributos {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          flex-direction: row;
          span {
            flex: 0 0 25%;
            svg {
              margin-right: 9px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .anuncios-similares {
        h5 {
          color: $imoveis;
          &:after {
            background: $imoveis;
          }
        }
        .anuncios {
          .anuncio {
            .info {
              small {
                color: $imoveis;
              }
              .btn {
                background: $imoveis;
              }
            }
          }
        }
      }
    }
    &.oportunidades-e-leiloes {
      aside {
        .form {
          background: $oportunidades;
        }
        .price {
          h3 {
            color: $oportunidades;
          }
          button {
            background: $oportunidades;
          }
        }
      }
      .container-info {
        h5 {
          color: $oportunidades;
        }
      }
      .anuncios-similares {
        h5 {
          color: $oportunidades;
          &:after {
            background: $oportunidades;
          }
        }
        .anuncios {
          .anuncio {
            .info {
              small {
                color: $oportunidades;
              }
              .btn {
                background: $oportunidades;
              }
            }
          }
        }
      }
    }
    .container {
      max-width: 950px;
    }
    aside {
      > * {
        padding: 25px;
      }
      .form {
        position: relative;
        .success {
          position: absolute;
          bottom: 25px;
          right: 25px;
          color: #fff;
        }
      }
      .price {
        box-shadow: 0 0 50px 2px #00000022;
        position: relative;
        min-height: 83px;
        label {
          font-size: 14px;
          font-weight: 900;
          color: #484848;
        }
        h3 {
          font-size: 20px;
          font-weight: 400;
          color: #0099DA;
          margin-bottom: 10px;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #979797;
          &.iptu, &.ipva {
            text-transform: uppercase;
          }
        }
        button {
          position: absolute;
          right: 25px;
          top: 25px;
          background: #1C6280;
          color: #fff;
          font-family: 'Lato', sans-serif;
          font-size: 13px;
          line-height: 13px;
          font-weight: 900;
          padding: 9px 20px;
        }

        .phone-popup {
          margin-bottom: 20px;
        }

        .btn-phone-closed {
             position: static;
             margin-left: 50px;
             color: #484848;
             border-color:#484848;
             border-radius: 8px;
        }
      }
      .form {
        h3 {
          font-size: 18px;
          font-weight: 900;
          text-transform: uppercase;
          color: #fff;
          margin-bottom: 20px;
        }
        input, textarea {
          font-family: 'Lato', sans-serif;
          margin: 13px 0;
          padding: 10px 15px;
          border-radius: 5px;
          border: 0;
          width: 100%;
          display: block;
          font-size: 16px;
          &::placeholder {
            font-style: italic;
            color: #979797;
          }
        }
        textarea {
          min-height: 115px;
        }
        .btn-enviar {
          padding: 9px 35px;
          background: #6FDC46;
          color: #fff;
          font-family: 'Lato', sans-serif;
          font-weight: 900;
          font-size: 13px;
          line-height: 13px;
          text-transform: uppercase;
        }
      }
    }
    footer {
      margin-top: 120px;
    }
    .slick-slider, .slick-slider * {
      height: 350px;
    }
    .slick-slider {
      width: 100%;
      margin-bottom: 50px;
      .slick-arrow {
        z-index: 1;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 50%;
        svg {
          width: 11px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:before {
          display: none;
        }
      }
      .slick-prev {
        left: 50px;
      }
      .slick-next {
        right: 50px;
      }
    }
    .breadcrumb {
      font-size: 14px;
      color: #979797;
      a {
        text-decoration: none;
        color: #979797;
      }
      .breadcrumb-item {
        &.active {
          color: #979797;
        }
      }
    }
    .btn-voltar {
      background: #F5F5F5;
      padding: 9px 30px;
      margin: 25px 0;
      font-size: 13px;
      line-height: 13px;
      color: #484848;
      font-weight: bold;
      text-transform: uppercase;
    }
    .container-info {
      margin-bottom: 115px;
      margin-top: 50px;
      color: #979797;
      h1 {
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        margin: 0;
        text-transform: uppercase;
        color: #484848;
      }
      h2 {
        margin-top: 75px;
        font-weight: 900;
        font-size: 24px;
        color: #484848;
      }
      h5 {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color: #0099DA;
      }
      .atributos {
        flex-direction: column;
        h3 {
          font-size: 18px;
          font-weight: 800;
          margin: 20px 0 10px;
          color: #484848;
        }
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          width: 100%;
          li {
            flex: 0 0 33.33333333%;
            small {
              font-size: 12px;
            }
            p {
              font-weight: bold;
              color: #484848;
              margin: 0;
            }
          }
        }
      }
      .sub {
        font-size: 16px;
        line-height: 19px;
        color: #979797;
        margin-bottom: 12px;
      }
      .time {
        font-size: 12px;
        line-height: 14px;
        color: #979797;
        margin-bottom: 25px;
      }
    }
    .container-anuncios-similares {
      max-width: 1230px;
    }
    .anuncios-similares {
      margin-top: 300px;
      h5 {
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        padding: 0;
        margin: 0 0 10px;
        width: auto;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 4px;
          width: 183px;
          bottom: -10px;
        }
        + hr {
          margin: 0 0 40px;
        }
      }
      p {
        font-size: 14px;
        color: #484848;
      }
      h6 {
        font-size: 16px;
        font-weight: 700;
      }
      .anuncios {
        display: flex;
        gap: 24px;
        .anuncio {
          flex: 1;
          .info {
            padding: 30px;
            small {
              font-size: 14px;
              line-height: 17px;
              font-weight: 700;
              color: $autos;
            }
            h4 {
              font-size: 20px;
              line-height: 24px;
              font-weight: 900;
              color: #484848;
              margin-bottom: 0;
            }
            .localizacao {
              font-size: 14px;
              line-height: 17px;
              color: #979797;
              margin-bottom: 15px;
            }
            .valor {
              font-size: 20px;
              font-weight: 900;
              line-height: 24px;
              margin-bottom: 30px;
              span {
                font-weight: 400;
                color: #0099DA;
              }
            }
            .btn {
              color: #fff;
            }
          }
        }
      }
      .like-heart {
        display: inline-flex;
        margin-left: 100px;
        padding-top: 10px;
      }
      .btn-blue {
        color: white;
      }
      .each-slide {
        height: 351px;
      }
      .box-search {
        background-color: #2676e9 !important;
      }
    }
  }
}

.top-search {
  &.bg-carreiras-e-empregos {
    h2 {
      font-weight: 800;
    }
  }
  h2 {
    font-family: Lato;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
  }
  h4 {
    font-family: Lato;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
}


.top-search 

.top_header {
  background-color: black;
  height: 43px;
}

.header {
  width: 100%;
  left: 0;
  top: 0;
  margin-bottom: 176px;
  background-position: top;
  background-size: cover;
  background-image: url("images/header.jpg");
}

@media only screen and (min-width: 988px) {
  .anuncios-similares {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 623px) {
  .only-mobile {
    display: block !important;
  }
  .only-desk {
    display: none;
  }
}
.only-mobile {
  display: none;
}

.header {
  .titleHome {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    h1 {
      font-size: 40px;
      line-height: normal;
      color: white;
      font-weight: 400;
    }
    h2 {
      font-size: 45px;
      line-height: normal;
      font-weight: 700;
      color: #ffef61;
    }
  }
  .logo {
    width: 228px;
  }
  .subpage-menu {
    transform: translate(0, 50%);
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    .subpage-menu-item {
      flex: 1 1 10%;
      cursor: pointer;
      text-align: center;
      background-color: white;
      box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 100%;
      padding: 30px 0;
      text-decoration: none;
      color: #222222;
      p {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #393939;
        margin: 0;
      }
      .icon {
        margin-bottom: 23px;
        width: 50px;
      }
      .iconHover {
        display: none;
        width: 50px;
      }
      &:hover {
        background-color: #34bcf5;
        p {
          color: white;
        }
        .icon {
          display: none;
        }
        .iconHover {
          display: inline;
        }
      }

      .segmentClicked {
        p {
          color: white;
        }
      }
    }
  }
}

.buttonTop {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-size: 13px;
  margin: 20px;
  padding: 7px 20px 7px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

.buttonTop:hover {
  color: white;
  opacity: 0.7;
}

#buttons_top {
  text-align: right;
  right: 30px;
  position: relative;
  margin-top: 40px;
}

.btn-green {
  background-color: #628c28;
}

.logo-footer {
  font-size: 14px;
  img {
    margin-bottom: 20px;
  }
  nav {
    a {
      text-decoration: none;
      color: black;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
}

.menu-footer {
  ul {
    text-decoration: none;
    li {
      width: auto;
      float: left;
      padding: 3px;
      margin-right: 10px;
    }
  }
}

.anunciar {
  background-color: #004c6e;
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    background-image: url("./images/banner-quer-resultados.jpg");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  }
  
  .container {
    display: flex;
    position: relative;
    z-index: 1;
  }

  .logo {
    text-align: right;
    padding-top: 130px;
    padding-right: 25px;
    flex: 1;
  }

  .info {
    flex: 1;
    color: white;
    padding: 80px 0 80px 40px;
    p {
      font-family: Lato;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .destaque {
      color: #34bcf5;
      font-family: 'Lato', sans-serif;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .canais {
      margin-top: 30px;
      font-size: 15px !important;
      font-weight: 800;
      a {
        color: white;
        text-decoration: none;
        font-size: 15px !important;
        font-weight: 800;
        margin-bottom: 0;
      }
      .destaque {
        color: white;
        display: flex;
        flex-wrap: wrap;
        gap: 15px 30px;
        align-items: center;
        > div {
        }
        .chat, .whatsapp {
          flex: 0 0 calc(40% - 30px);
        }
        .telefone, .email {
          flex: 0 0 calc(60% - 30px);
        }
      }
    }
    .form-group {
      margin-bottom: 8px;
    }
    .btn-success {
      width: 214px;
      left: 756px;
      top: 1587px;
      text-transform: uppercase;
      background: #6fdc46;
      border-radius: 4px;
      font-weight: bold;
    }
    .success-message {
      margin-top: 10px;
      height: 0;
      transition: height 0.4s, padding 0.4s;
      overflow: hidden;
      background: #34bcf5;
      padding: 0 10px;
      border-radius: 3px;
      &.active {
        height: 44px;
        padding: 10px 10px;
      }
    }
    .btn-primary {
      width: 100%;
      text-transform: uppercase;
      background-color: #0091D0;
      border-radius: 4px;
      font-weight: bold;
      line-height: 35px;
      svg, img {
        margin-right: 5px;
      }
    }
  }
}

header {
  margin-top: 40px;
  margin-bottom: 40px;
  .buttonTop {
    display: inline-flex;
  }
}

.canais a {
  font-size: 15px;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
}

.canais a:hover {
  transition: color .2s;
  color: #34bcf5;
  &.btn-primary {
    background: #34bcf5;
    color: #fff;
  }
}

.footer-socialmedia {
  margin-top: 30px;
  p {
    float: left;
    margin-right: 20px;
    padding-top: 10px;
  }
  .btn-newsletter {
    display: inline-flex;
    width: 100%;
    text-decoration: none;
    margin-top: 20px;
    color: black;
    align-items: center;
    gap: 20px;
  }
}

.btn-social {
  margin: 3px;
  width: 40px;
  color: #2575e8;
  background-color: #F0F0F0;
  border: none;
  height: 40px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkboxSearch {
  width: 23px;
  height: 24px;
  border: 1px solid #e0e0e0 !important;
  content: " ";
  overflow: hidden;
  border-radius: 0px !important;
  margin-right: 5px !important;
}

.checkboxSearch input {
  display: none !important;
}

.checkboxSearch > svg {
  display: none !important;
}

.checkboxSearch.active {
  background-color: #0099da !important;
}

.checkboxSearchLabel {
  margin-bottom: 8px;
  margin-left: 0px !important;
  font-size: 0.9em !important;
}

.checkboxSearchLabel.Active span {
  color: #0099da !important;
}

.form-group input[type="text"],
.form-group select,
.form-group select option {
  font-weight: 400 !important;
  font-size: 14px;
  background-color: #ffffff !important;
}

input[type="checkbox"]:checked {
  content: "\f0aa";
}

.each-slide {
  height: 277px;
  background-size: contain;
}

.default-nav {
  background-color: #e0e0e0 !important;
  width: 24px !important;
  height: 24px !important;
  text-align: center;
  opacity: 0.7;
  margin: 120px 10px 0px 10px;
  justify-content: center;
  justify-items: center;
}

.default-nav:hover {
  transition: 0.4s;
  background-color: #2676e9;
}

.default-nav svg {
  width: 8px;
}

.each-slide .photo {
  height: 100%;
}

.top-search {
  background-size: cover;
  color: white;
  text-align: center;
  padding: 100px 0 153px;
  position: relative;
  margin-bottom: 120px;
}

.bg-carreiras-e-empregos {
  background-image: url("images/bg-searchcarrer.jpg");
}

.bg-autos {
  background-image: url("images/bg-auto.jpg");
}

.bg-oportunidades-e-leiloes {
  background-image: url("images/bg-oportunidade.jpg");
}

.bg-autos h2,
.bg-imoveis h2,
.bg-oportunidades-e-leiloes h2 {
  color: #ffef61;
}

.bg-imoveis {
  background-image: url("images/bg-imoveis.jpg");
}

.box-search {
  background-color: #0099da;
  min-height: 106px;
  border-radius: 5px;
  text-align: left;
  padding: 18px;
  width: calc(100% - 60px);
  position: absolute;
  max-width: 1280px;
  box-sizing: border-box;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 50%;
  transform: translate(-50%, 50%);
  gap: 30px;
  > * {
    flex: 1;
  }
}

.bg-autos {
  .box-search {
    > * {
      &:nth-child(5) {
        flex: 0;
      }
    }
  }
}
.bg-carreiras-e-empregos {
  .box-search {
    > * {
      &:nth-child(1) {
        flex: 2;
      }
      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        flex: 1;
      }
      &:nth-child(5) {
        flex: 3;
      }
    }
  }
}

.bg-autos .box-search {
  background-color: #2676e9 !important;
}

.box-search label {
  margin-bottom: 8px;
  font-style: italic;
  white-space: nowrap;
}

.box-search select {
  color: black !important;
}

.btn-50 {
  width: 47%;
}

.btn-assine {
  background-color: #539c3f;
  color: white;
  padding: 8px;
  display: block;
  border-radius: 6px;
  line-height: 10px;
  text-decoration: none;
}

.btn-assine:hover {
  background-color: white;
  color: #539c3f;
  border: solid 1px #539c3f;
}

.btn-assine p,
.btn-assine b {
  font-size: 11px;
}

.btn-blue {
  background-color: #102c4e;
}

.btn-blue3 {
  background-color: #1c6280;
  color: white !important;
}

.box-destaque .results {
  text-align: center;
}

.box-destaque .loading {
  margin-top: 80px;
  margin-bottom: 80px;
}

.btn-search {
  margin-top: 28px;
  background-color: #102c4e !important;
}

.box-destaque {
  display: block;
}

.menu-search {
  label {
    display: block;
    align-content: center;
    justify-content: center;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    line-height: 19.2px;
    color: #000000;
    margin: 0;
  }
  .filter-group {
    margin-bottom: 70px;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    button {
      min-width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 0;
      cursor: pointer;
      background: #F2F2F2;
      transition: background-color 0.4s;
      &:hover {
        background: #e1e1e1;
      }
      &.active {
        background: #d0d0d0;
      }
    }
  }
  .dorms, .garagens, .combustivel {
    .buttons {
      justify-content: space-between;
    }
  }
  .cambio {
    .buttons {
      margin: 0 -2.5px;
      button {
        margin: 2.5px;
      }
    }
  }
}

.accordion-item {
  border: none;
}

.accordion-header button {
  background-color: #f1f1f1 !important;
  width: 100%;
  height: initial;
  padding: 0;
  box-shadow: none;
  padding: 12px 15px 13px;
  border-radius: initial !important;
  align-items: center;
}

.invalid-feedback {
  color: #fff;
  margin: 0 0 10px;
  margin-top: -10px;
}

.carrer .checkboxSearch.active {
  background-color: #628c28 !important;
}

.carrer .checkboxSearchLabel.Active span {
  color: #628c28 !important;
}
.accordion-button:not(.collapsed)::after {
  content: "+";
  font-family: "Lato";
  background: none;
  font-size: 20px;
  font-weight: bold;
}

.accordion-button::after {
  content: "-";
  background: none;
  font-size: 40px;
  color: #4972a2;
}
.carrer .accordion-button::after {
  color: #628c28;
}

.carrer .accordion-button:not(.collapsed)::after {
  color: #628c28;
}

.imoveis .accordion-button::after {
  color: #0099da;
}

.imoveis .accordion-button:not(.collapsed)::after {
  color: #0099da;
}
.menu-search .form-group {
  margin-bottom: 30px;
}

.TopEstadao {
  padding-top: 20px;
  max-width: 100%;
}

.box-news {
  margin-top: 30px;
  height: 100px;
  justify-content: center;
  align-content: center;
  border: solid 1px #c9c9c9;
}

.result-actions {
  justify-content: right;
  text-align: right;
  padding-right: 20px;
}

.result-actions .btn-mais {
  width: 100%;
  max-width: 130px;
}

.btn-blue {
  background-color: #547BA8;
  border: none;
  transition: 0.4s;
}

.btn-blue2 {
  background-color: #4972a2;
  border: none;
  transition: 0.4s;
}

.box-shadow {
  background-color: white;
  box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;
  line-height: 20px;
}

.box-detail {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  display: flex;
  flex-flow: nowrap;
  small {
    font-size: 14px;
    font-weight: 600;
    color: #0099da;
  }
  h4 {
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
  }
  &.box-career {
    h4 {
      color: #484848;
    }
  }
  &.box-imoveis {
    .content {
      margin-bottom: 15px;
    }
    .cidade {
      font-weight: bold;
      color: #979797;
    }
    .atributos {
      width: 320px;
    }
  }
  &.box-opportunity {
    h4 {
      margin-bottom: 15px;
    }
  }
  .images {
    width: 350px;
    * {
      height: 100%;
    }
    .photo {
      background-position: center;
      background-size: cover;
    }
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .slick-arrow {
              z-index: 1;
              width: 22px;
              height: 22px;
              background: #fff;
              border-radius: 50%;
              position: relative;
              &.slick-prev {
                left: 7px;
              }
              &.slick-next {
                right: 7px;
              }
              &:before {
                display: none;
                content: '';
              }
              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: #009add;
                width: 6px;
                height: 10px;
              }
            }
          }
        }
      }
    }
  }
  .info {
    padding: 30px;
    flex: 1;
    .btn-success {
      font-size: 13px;
    }
  }
  .like {
    padding: 10px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    text-align: center;
    border: solid 1px #e0e0e0;
    &:hover {
      background-color: #004c6e;
      color: white;
      transition: 0.3s;
    }
  }
  .btn-green {
    margin-top: 40px;
  }
  .content {
    text-align: justify;
    font-weight: 400;
  }
  .details {
    margin-top: 30px;
    font-weight: 400;
    font-size: 14px;
    color: #979797;
  }
  .descricao {
    text-overflow: ellipsis;
    height: 17px;
    overflow: hidden;
    white-space: nowrap;
  }
  .price {
    font-size: 20px;
    color: #0099da;
    font-weight: 500;
    b {
      font-size: 18px;
      font-weight: 800;
      color: #484848;
    }
  }
  .atributos {
    display: flex;
    span {
      flex: 1;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #979797;
      svg {
        margin-right: 7px;
      }
    }
  }
  .details {
    font-size: 10px !important;
    img {
      margin-right: 2px;
    }
  }
  .content {
    margin-top: 0 !important;
    margin-bottom: 30px;
    color: #979797;
    word-break: break-all;
    .cargo {
      font-weight: bold;
    }
  }
}

.btn-yellow {
  background-color: #fff;
  color: #206395;
  font-weight: 500;
  margin-right: 5px;
  transition: 0.3s;
  &:last-child {
    margin-right: 0;
  }
}

.y-checked {
  background-color: #ffef61;
}

.btn-yellow:hover {
  background-color: #ffef61 !important;
  border: solid 1px #0099da;
  color: #206395 !important ;
}

.buttons-search {
}

.debug {
  border: solid 1px red;
}

.view-images {
  min-height: 260px;
}

.detail-page small {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;

  color: #0099da;
}

.detail-page h1 {
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;
  text-transform: uppercase;
}
.detail-page h1.menor{
  font-size:18px;
}

.detail-page address {
  color: #979797;
  margin-bottom: 20px;
  width: 80%;
}

.detail-page .detail {
  color: #484848;
}

.detail-page-box {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 50px 2px rgba(0, 0, 0, 0.1);
}

.favoritos {
  color: #484848;
  text-decoration: none;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.view-map {
  background-color: white;
  border: none;
  color: #0099da;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  justify-items: right;
  margin-left: 160px;
  margin-top: -20px;
}

.view-map:hover {
  background-color: #0099da !important;
  color: white;
}

.box-view-right {
  text-align: right;
}

.box-view-right .row {
  text-align: left;
}

.detail-page-box-title {
  height: 20vh;
}

.detail-page-box-content {
  background-color: #0099da;
  border-radius: 6px;
  color: #484848;
}

.detail-page-box-content h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

.detail-page-box-content input {
  margin-bottom: 10px;
}
.detail-page-box-content textarea {
  margin-bottom: 10px;
}

.detail-page-box-content .btn-green {
  background-color: #6fdc46 !important;
  width: 121px;
  height: 34px;
  color: white;
  margin-top: 10px;
  text-transform: uppercase;
}

.like-detail {
  padding-top: 6px;
  border-radius: 40px;
  width: 35px;
  height: 35px;
  margin: 10px;
  text-align: center;
  justify-content: center;
  align-content: center;
  border: solid 1px #484848;
}

.detail-page .result-actions {
  margin-top: -100px;
  text-align: right;
}

.detail-page .result-actions > .like-detail {
  position: relative;
  transition: 0.4s;
}

.like-heart {
  color: #fb365a;
  border: solid 1px #d9d9d9;
  transition: 0.4s;
}

.like-detail:hover {
  background-color: #efefef;
}

.detail-page .details {
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #979797;
}

.btn-silver {
  width: 130px;
  height: 36px;
  background: #f5f5f5;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 13px;
}

.detail-page img {
  margin-right: 10px;
}

.detail-page-box-title p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #979797;
}

.detail-page-box-title .price {
  font-size: 20px;
  color: #0099da;
}
.detail-page-box-title small {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
}

.carrer .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #628c28;
  color: #fff;
}

.oportunidades
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #5fbebd;
  color: #fff;
}

.box-career.box-shadow .btn-mais {
  background-color: #628c28;
}

.imoveis .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #0099da;
  color: #fff;
}

.box-career.box-shadow .result-actions .btn-mais {
  margin-top: 25px !important;
}

.horizontal-scrollable {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.item-horizontal {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  height: 500px;
}

.media-lab-item {
  width: 500px;
  height: 500px;
  display: table-cell;
  vertical-align: inherit;
}

.footer__text h2 {
  font-size: 28px;
  color: #0099da;
  position: relative;
}
.footer__text h2::before {
  content: "";
  width: 60px;
  height: 4px;
  display: block;
  background: #0099da;
  position: absolute;
  top: -10px;
  left: 15px;
}
.footer__text p {
  font-size: 1rem;
}

.container--vitrine {
  max-width: 1190px;
  margin: 50px auto 100px;
}

.footer__infos {
  border-top: 1px solid #d9d9d9;
  padding-top: 80px;
}

.footer__ad-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 988px) {
  .footer__ad-item {
    margin-bottom: 50px;
  }
}

.flex-busca {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-busca .form-control {
  width: 75%;
  margin-bottom: 0;
}
.flex-busca .btn {
  width: 20%;
  margin-bottom: 0;
  margin-top: 0px !important;
}

.btn-lk{
  background: transparent !important;
  border: none !important;
  color: black !important;
}


.btn-group-justified{display:table;width:100%;table-layout:fixed;border-collapse:separate}
.btn-group-justified>.btn,
.btn-group-justified>.btn-group{display:table-cell;float:none;width:33%;}
.btn-group-justified>.btn-group .btn{width:100%;}
.btn-group-justified .btn{
  font-size: 0.8em !important;
  padding: 5px 6px !important;
  margin: 0px !important;
}
.btn-group-justified>.btn-group .dropdown-menu{left:auto}[data-toggle=buttons]>.btn input[type=checkbox],[data-toggle=buttons]>.btn input[type=radio],[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],[data-toggle=buttons]>.btn-group>.btn input[type=radio]{position:absolute;clip:rect(0,0,0,0);pointer-events:none}.input-group{position:relative;display:table;border-collapse:separate}

.box-content {
  h5 {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }
}
.destaques {
  margin-bottom: 140px;
  > .slick-slider {
    > .slick-arrow {
      z-index: 1;
      top: auto;
      bottom: 0;
      transform: translate(0,0);
      height: 30px;
      width: 30px;
      box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.1);
      svg {
        fill: #547BA8;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: fill .2s;
      }
      &.slick-disabled {
        svg {
          fill: #BDBDBD;
        }
      }
      &.slick-prev {
        right: 50px;
        left: auto;
      }
      &.slick-next {
        right: 0;
      }
      &:before {
        display: none;
        content: '';
      }
    }
    > .slick-list {
      margin: 0 -30px;
      padding: 50px 0;
      > .slick-track {
        > .slick-slide {
          padding: 0 30px;
          .box-detail {
            display: flex;
            min-height: 343px;
            box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.1);
            > .images {
              width: 50%;
              * {
                height: 100%;
              }
              .slick-arrow {
                z-index: 1;
                width: 22px;
                height: 22px;
                background: #fff;
                border-radius: 50%;
                position: relative;
                &.slick-prev {
                  left: 7px;
                }
                &.slick-next {
                  right: 7px;
                }
                &:before {
                  display: none;
                  content: '';
                }
                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  fill: #009add;
                  width: 6px;
                  height: 10px;
                }
              }
            }
            .info {
              width: 50%;
              padding: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              small {
                font-size: 12px;
                color: #4972A2;
              }
              h4 {
                font-size: 24px;
                color: #484848;
                line-height: 24px;
                font-weight: 900;
              }
              .content {
                margin-bottom: 17px;
                .ano {
                  margin-bottom: 17px;
                }
              }
              a {
                margin-top: auto;
                width: 110px;
                font-size: 13px;
              }
              p {
                margin: 0;
                &.price {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.menu-search {
  display: flex;
  gap: 40px;
}
.filter {
  flex: 0 0 300px;
  position: relative;
  h3 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .logo {
    width: 100px;
    margin-bottom: 35px;
    display: none;
  }
  .close-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 45px;
    right: 30px;
    display: none;
  }
  .filterItem {
    h2 {
      font-size: 16px;
      line-height: 19.2px;
      button {
        &.accordion-button {
          &:not(.collapsed) {
            color: #484848;
            border: 0;
            box-shadow: none;
          }
        }
      }
    }
    .accordion {
      margin-bottom: 30px;
    }
  }
}
.contentList {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  .btn-filter {
    background: #F1F1F1;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    color: #979797;
    cursor: pointer;
    display: none;
    svg {
      margin-left: 15px;
    }
    &.active {
      background: #102C4E;
      color: #fff;
    }
  }
  .box-detail {
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: 2;
    }
    &:nth-child(4) {
      order: 3;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 6;
    }
    &:nth-child(7) {
      order: 7;
    }
  }
  .ads {
    order: 3;
  }
  .MuiPagination-root {
    order: 8;
  }
  .no-result {
    background: #e2e3e5;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #d3d6d8;
  }
}

.clear-filter {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .item {
    background: #f1f1f1;
    border-radius: 10px;
    color: #979797;
    margin: 0 3px;
    padding: 4px 10px;
    cursor: pointer;
    transition: background .2s;
    i {
      margin-left: 5px;
    }
    &:last-child {
      background: #0099da;
      color: #fff;
    }
  }
}

.nav {
  max-height: none;
  top: 0;
  float: right;
  width: fit-content;
  &.active {
    transform: translate(0);
  }
}
.mobile-menu {
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  padding: 25px 36px;
  left: 0;
  transform: translateX(100%);
  transition: 0.5s ease-in-out;
  max-height: initial;
  top: 0;
  z-index: 999999999;
  margin: 0;
  background: #fff;
  width: 100%;
  .close-icon {
    position: absolute;
    right: 30px;
    top: 50px;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
  .logo {
    margin-bottom: 40px;
  }
  .menu {
    padding: 0;
    margin: 0;
    a {
      font-size: 20px;
      line-height: 48px;
      text-decoration: none;
      display: block;
      color: #000;
    }
  }
  .buttonTop {
    margin: 0;
  }
  .enter-menu {
    display: flex;
    a {
      &:first-child {
        background: #000;
        color: #fff !important;
        display: flex;
        align-items: center;
      }
    }
  }
}