:root {
  --white: #f9f9f9;
  --black: #36383f;
  --gray: #85888c;
} /* variables*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}

.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2676e9;
  overflow: hidden;
  margin-top: 10px;
}

.no-scroll {
  overflow: hidden;
}
.nav {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* Menu Icon */
.hamb {
  position: absolute;
  cursor: pointer;
  padding: 50px 20px;
  right: 2px;
  top: 45px;
} /* Style label tag */

.hamb-line {
  background: var(--white);
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
} /* Style span tag */

.hamb-line::before,
.hamb-line::after {
  background: var(--white);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.hamb-line::before {
  top: 5px;
}
.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
} /* Hide checkbox */

/* Toggle menu icon */
.side-menu:checked ~ nav {
  max-height: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

.side-menu:checked ~ nav {
  max-height: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}