@media (max-width: 1199px) {
  .anunciar {
    .info {
      .canais {
        .destaque {
          .chat, .whatsapp {
            flex: 0 0 100%;
          }
          .telefone, .email {
            flex: 0 0 100%;
          }
          .chat {
            order: 1;
          }
          .whatsapp {
            order: 2;
          }
          .telefone {
            order: 3;
          }
          .email {
            order: 4;
          }
        }
      }
      .success-message {
        &.active {
          height: 68px;
        }
      }
    }
  }
  .box-search {
    gap: 15px;
  }
  .box-detail {
    flex-direction: column;
    .images {
      width: 100%;
      height: 300px;
    }
  }
  .destaques {
    > .slick-slider {
      > .slick-list {
        > .slick-track {
          > .slick-slide {
            .box-detail {
              > .images {
                width: 100%;
                height: 300px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .header {
    margin-bottom: 70px;
    .titleHome {
      h1 {
        font-size: 34px;
      }
      h2 {
        font-size: 38px;
      }
    }
    .subpage-menu {
      padding-bottom: 50px;
      transform: translate(0,0);
      .subpage-menu-item {
        flex: 1 1 48%;
      }
    }
  }
  .anunciar {
    &:before {
      opacity: .28;
    }
  }
  .top-search {
    padding: 30px 0 170px;
    &.bg-autos {
      margin-bottom: 350px;
    }
    &.bg-carreiras-e-empregos {
      margin-bottom: 383px;
    }
    &.bg-imoveis {
      margin-bottom: 345px;
    }
    &.bg-oportunidades-e-leiloes {
      margin-bottom: 380px;
    }
    h2 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
  }
  .bg-searchcarrer {
    padding-top: 30px;
    height: 315px;
    margin-bottom: 280px;
  }
  .box-search {
    flex-direction: column;
    transform: translate(-50%, calc(100% - 140px));
  }
  .btn-search {
    margin-top: 0;
    width: 100%;
    padding: 15px 0;
  }
  .destaques {
    h5 {
      text-align: center;
    }
  }
  .filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 45px;
    z-index: 9999999;
    opacity: 0;
    transform: translate(100%, 0);
    transition: transform .8s, opacity .8s;
    overflow: auto;
    &.active {
      opacity: 1;
      transform: translate(0, 0);
    }
    .logo {
      display: block;
    }
    .close-icon {
      display: block;
    }
  }
  .contentList {
    .btn-filter {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .App {
    padding-top: 50px;
    &.view {
      .anuncios-similares {
        display: none;
      }
    }
  }
  .header {
    margin-bottom: 355px;
    .titleHome {
      h1 {
        font-size: 26px;
      }
      h2 {
        font-size: 26px;
      }
    }
    .logo {
      width: 190px;
    }
    .subpage-menu {
      .subpage-menu-item {
        flex: 1 1 40%;
        p {
          font-size: 16px;
        }
      }
    }
  }
  .anunciar {
    .container {
      flex-direction: column;
    }
    .logo {
      padding-top: 50px;
      padding-right: 0;
      text-align: center;
      img {
        width: 195px;
      }
    }
    .info {
      padding: 50px 0;
      text-align: center;
      p, .destaque {
        text-align: center;
      }
      .success-message {
        &.active {
          height: 44px;
        }
      }
    }
  }
  .box-detail {
    .images {
      height: 190px;
    }
  }
  .result-actions .btn-mais {
    position: relative;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 575px) {
  .header {
    .subpage-menu {
      gap: 30px;
      .subpage-menu-item {
        padding: 20px 0;
        p {
          font-size: 12px;
          padding: 0 10px;
          line-height: 14px;
        }
        .icon, .iconHover {
          width: 30px;
        }
      }
    }
  }
  #header {
    .logo-editoria {
      width: 100%;
      max-width: 220px;
    }
    .hamb {
      font-size: 20px;
    }
  }
  .anunciar {
    .info {
      .success-message {
        &.active {
          height: 68px;
        }
      }
    }
  }
}